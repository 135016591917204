<template>
  <template v-if="!loading">
    <refonte-card>
      <interlocuteurs-filters />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <refonte-table-number
          v-bind:number="interlocuteurs.length"
          v-bind:label="
            interlocuteurs.length > 1 ? 'Interlocuteurs' : 'Interlocuteur'
          " />
        <IconSeparatorVertical />
        <refonte-button
          v-bind:disabled="activeEntrepriseIds.length !== 1"
          v-bind:on_click="() => openInterlocuteurModalOpen()"
          v-bind:title="
            activeEntrepriseIds.length !== 1
              ? 'Veuillez séléctionner un établissement pour pouvoir cliquer sur ce bouton'
              : undefined
          ">
          Ajouter interlocuteur
        </refonte-button>
      </div>
      <refonte-separator class="px-[12px]" />
      <interlocuteurs-content
        v-if="!dataLoading"
        v-bind:update_interlocuteur="updateInterlocuteur"
        v-bind:delete_interlocuteur="deleteInterlocuteur" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
  <refonte-modal-interlocuteur
    v-if="!modalLoading"
    v-bind:show="isInterlocuteurModalOpen"
    v-bind:on_close="() => closeInterlocuteurModalOpen()"
    v-bind:on_submit="addOrUpdateInterlocuteur"
    v-bind:active_interlocutor="activeInterlocuteur"
    v-bind:available_participants="participants" />
</template>

<script setup lang="ts">
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import InterlocuteursContent from '@/views/_espace/_refonte/gestion/_interlocuteurs/interlocuteurs-content.vue';
  import {
    useInterlocuteurFilterStore,
    useInterlocuteurStore
  } from '@/stores/interlocuteurs';
  import { storeToRefs } from 'pinia';
  import RefonteTableNumber from '@/components/refonte/numbers/refonte-table-number.vue';
  import InterlocuteursFilters from '@/views/_espace/_refonte/gestion/_interlocuteurs/interlocuteurs-filters.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import RefonteModalInterlocuteur from '@/components/refonte/modals/refonte-modal-interlocuteur.vue';
  import {
    AnnuaireInterlocuteur,
    Interlocuteur,
    InterlocuteurForm
  } from '@/types/gestion/interlocuteurs-types';
  import { API } from '@/http-common';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeEntrepriseIds, activeAdminClientIds } =
    storeToRefs(entrepriseStore);
  const interlocuteurFilterStore = useInterlocuteurFilterStore();

  const { filters } = storeToRefs(interlocuteurFilterStore);

  const dataLoading = ref<boolean>(false);
  const interlocuteurStore = useInterlocuteurStore();
  const { interlocuteurs } = storeToRefs(interlocuteurStore);

  const fetchData = async () => {
    dataLoading.value = true;
    await interlocuteurStore.fetchInterlocuteurs(route.query);
    dataLoading.value = false;
  };

  watch(activeEntrepriseIds, () => {
    fetchData();
  });

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );

  const initFilters = () => {
    interlocuteurFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(() => {
    initFilters();
    fetchData();
  });

  const router = useRouter();
  watch(filters, async () => {
    let query = api_utils.methods.formatParams(filters.value, []);
    if (activeAdminClientIds.value && activeAdminClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeAdminClientIds.value
      };
    }
    await router.push({ query });
  });

  const activeInterlocuteur = ref<Interlocuteur>();
  const isInterlocuteurModalOpen = ref<boolean>(false);

  function closeInterlocuteurModalOpen() {
    isInterlocuteurModalOpen.value = false;
    activeInterlocuteur.value = undefined;
  }
  const modalLoading = ref<boolean>(false);
  const participants = ref<AnnuaireInterlocuteur[]>();
  const openInterlocuteurModalOpen = async () => {
    await fetchParticipants();
    activeInterlocuteur.value = undefined;
    isInterlocuteurModalOpen.value = true;
  };
  const fetchParticipants = async () => {
    modalLoading.value = true;
    const response = await API.get(
      `client/v2/${activeEntrepriseIds.value[0]}/interlocuteurs/annuaires`,
      { headers: { 'SKIP-REDIRECTION': true } }
    );
    if (response && response.data) {
      participants.value = response.data.map((p) => ({
        ...p,
        nomPrenom: `${p.nom} ${p.prenom}`
      }));
    }
    modalLoading.value = false;
  };
  function updateInterlocuteur(value: Interlocuteur) {
    isInterlocuteurModalOpen.value = true;
    activeInterlocuteur.value = value;
  }

  const deleteInterlocuteur = async (value: Interlocuteur) => {
    const { clientId, id } = value;
    await API.delete(`client/v2/${clientId}/interlocuteurs/${id}`, {
      headers: {
        'SKIP-REDIRECTION': true
      }
    });
    await fetchData();
  };

  async function addOrUpdateInterlocuteur(interlocuteur: InterlocuteurForm) {
    if (activeInterlocuteur.value) {
      await API.put(
        `client/v2/${activeInterlocuteur.value?.clientId}/interlocuteurs/${activeInterlocuteur.value?.id}`,
        interlocuteur
      );
    } else {
      await API.post(
        `client/v2/${activeEntrepriseIds.value[0]}/interlocuteurs`,
        interlocuteur
      );
    }
    closeInterlocuteurModalOpen();
    await fetchData();
  }
</script>

<style lang="scss" scoped></style>
