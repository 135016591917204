<template>
  <template v-if="!loading && detail">
    <inscriptions-details-main v-bind:detail="detail" />
    <refonte-collapse
      v-if="detail.titre"
      class="mt-[12px]"
      v-bind:title_icon="IconMyAccount"
      title="Participant"
      v-bind:start_open="true">
      <template v-if="detail.participant">
        <refonte-table
          key="participant-1"
          table_index="participant-1"
          v-bind:columns="columnsParticipant"
          v-bind:data="[detail.participant]">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre"
      class="mt-[12px]"
      v-bind:title_icon="IconTestValidation"
      title="Documents"
      v-bind:start_open="true">
      <div class="flex flex-wrap gap-[8px] pb-[12px]">
        <refonte-item-file
          v-if="detail.convention?.id"
          title="Convention collective"
          v-bind:on_click_download="
            () => downloadConvention(detail?.clientId, detail?.convention)
          "
          v-bind:file_signed="
            detail.convention.signee || detail.convention.validee
          " />
        <refonte-item-file
          title="Convention individuelle"
          v-if="detail?.conventionIndividuelle?.id"
          v-bind:on_click_download="
            () =>
              downloadConvention(
                detail?.clientId,
                detail?.conventionIndividuelle
              )
          "
          v-bind:file_signed="
            detail.conventionIndividuelle.signee ||
            detail.conventionIndividuelle.validee
          " />
        <refonte-item-file
          title="PIF"
          v-if="detail.pif?.id"
          v-bind:on_click_download="
            () => downloadPif(detail?.clientId, detail?.pif)
          "
          signed_label="Signé"
          v-bind:file_signed="detail.pif?.signee || detail.pif.validee" />
        <refonte-item-file
          title="Certificat"
          v-bind:no_download_text="detail.termine ? '-' : 'A venir'"
          v-bind:on_click_download="
            detail.certificatId
              ? () => downloadCertificat(detail?.clientId, detail?.certificatId)
              : undefined
          "
          v-bind:no_signature_needed="true" />
        <refonte-item-file
          title="Bon de commande"
          v-if="detail.bonCommandeId"
          v-bind:on_click_download="
            detail.bonCommandeId
              ? () => downloadBonCommande(detail?.clientId, detail?.commandeId)
              : undefined
          "
          v-bind:no_signature_needed="true" />
      </div>
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.factures && detail.factures.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconFactures"
      title="Factures"
      v-bind:start_open="true">
      <template v-if="detail.factures && detail.factures.length > 0">
        <refonte-table
          key="factures-1"
          table_index="factures-1"
          v-bind:columns="columnsFactures"
          v-bind:data="detail.factures">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.prestations && detail.prestations.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconList"
      title="Prestations"
      v-bind:start_open="true">
      <template v-if="detail.prestations">
        <refonte-table
          key="prestations-1"
          table_index="prestations-1"
          v-bind:columns="columnsPrestationsPrices"
          v-bind:data="detail.prestations">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.programmes && detail.programmes.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconDayAgenda"
      title="Détails">
      <template v-if="detail.programmes.length">
        <div
          v-for="(programme, index) in detail.programmes"
          v-bind:key="`programme-${index}`">
          <refonte-separator
            v-if="index > 0 && detail.programmes.length > 1"
            class="px-[12px]" />
          <refonte-label
            v-if="programme.titre && detail.programmes.length > 1"
            v-bind:label="programme.titre"
            class="px-[16px]" />
          <refonte-table
            key="prestations-1"
            table_index="prestations-1"
            v-bind:columns="columnsPrestations"
            v-bind:data="
              programme.prestations.map((p: Prestation) => ({
                ...p,
                nom: p.nom ? p.nom : p.typeId == 2 ? 'Repas' : p.titre,
                lieuNom: p.site ? p.site.denomination : undefined
              }))
            ">
          </refonte-table>
        </div>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="
        detail.titre &&
        detail.otherParticipants &&
        detail.otherParticipants.length > 0
      "
      class="mt-[12px]"
      v-bind:title_icon="IconConventionCollective"
      v-bind:title="`Autres participants (${detail.otherParticipants.length})`">
      <template v-if="detail.otherParticipants">
        <refonte-table
          v-bind:on_details="goToDetailsParticipant"
          key="otherParticipants-1"
          table_index="otherParticipants-1"
          v-bind:columns="columnsParticipants"
          v-bind:data="detail.otherParticipants"
          class="table-no-space-between">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
  </template>
  <spinner
    v-else
    sizes="w-20 h-20"
    thickness="border-8" />
</template>
<script setup lang="ts">
  import Spinner from '@/components/utils/spinner.vue';
  import { computed, h, onMounted, ref, watch } from 'vue';
  import InscriptionsDetailsMain from '@/views/_espace/_refonte/gestion/_inscriptions-details/inscriptions-details-main.vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import IconDayAgenda from '@/assets/img/refonte/icons/information/icon-day-agenda.svg?component';
  import IconTestValidation from '@/assets/img/refonte/icons/information/icon-test-validation.svg?component';
  import IconMyAccount from '@/assets/img/refonte/icons/users/icon-my-account.svg?component';
  import IconConventionCollective from '@/assets/img/refonte/icons/users/icon-convention-collective.svg?component';
  import IconFactures from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import {
    Detail,
    Participant,
    Prestation,
    PrestationPrice
  } from '@/types/gestion/inscriptions-details-types';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import dates_helpers from '@/mixin/dates_helpers';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import RefonteItemFile from '@/components/refonte/items/refonte-item-file.vue';
  import IconList from '@/assets/img/refonte/icons/information/icon-list.svg?component';
  import number_utils from '@/mixin/number_utils';
  import IconModify from '@/assets/img/refonte/icons/action/icon-modify.svg?component';
  import { Facture } from '@/types/gestion/factures-types';
  import { useRoute, useRouter } from 'vue-router';
  import { API } from '@/http-common';
  import {
    downloadBonCommande,
    downloadCertificat,
    downloadConvention,
    downloadPif
  } from '@/mixin/client_download__functions';

  const detail = ref<Detail>();
  const route = useRoute();
  const loading = ref<boolean>(false);
  const id = computed(() => route.query.id);

  watch(
    () => route.query.id,
    () => fetchInscription()
  );

  const fetchInscription = async () => {
    loading.value = true;
    const response = await API.get(
      `client/v2/inscriptions/session/${id.value}/details`
    );

    if (response && response.data) {
      detail.value = mapInscriptionDetail(response.data);
    }
    loading.value = false;
  };

  const mapInscriptionDetail = (detail) => {
    return { ...detail };
  };
  onMounted(async () => {
    await fetchInscription();
  });

  const columnsPrestations = [
    {
      content_index: 'nom',
      date: 'date',
      width: 308
    },
    {
      content_index: 'heureDebut',
      label: 'Heure de début',
      width: 120,
      content_custom: (row: Prestation) =>
        h('span', {
          innerHTML: dates_helpers.methods.formatMinutesToHours(row.heureDebut)
        })
    },
    {
      content_index: 'heureFin',
      label: 'Heure de fin',
      width: 120,
      content_custom: (row: Prestation) =>
        h('span', {
          innerHTML: dates_helpers.methods.formatMinutesToHours(row.heureFin)
        })
    },
    {
      content_index: 'dureeMinutes',
      label: 'Durée',
      width: 90,
      content_custom: (row: Prestation) =>
        h('span', {
          innerHTML: row.dureeMinutes
            ? dates_helpers.methods.formatMinutesToHours(row.dureeMinutes)
            : '-'
        })
    },
    {
      content_index: 'lieuNom',
      label: 'Site',
      width: 150
    }
  ];

  const columnsPrestationsPrices = [
    {
      content_index: 'libelle',
      label: 'Libellé',
      width: 308
    },
    {
      content_index: 'montantHT',
      label: 'Montant HT',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantHT)
        })
    },
    {
      content_index: 'montantTVA',
      label: 'Montant TVA',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantTVA)
        })
    },
    {
      content_index: 'montantTTC',
      label: 'Montant TTC',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantTTC)
        })
    },
    {
      content_index: 'montantRegle',
      label: 'Montant réglé',
      width: 120,
      content_custom: (row: PrestationPrice) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantRegle)
        })
    }
  ];
  const router = useRouter();
  const goToDetailsParticipant = (row: Participant) => {
    router.replace(`/refonte/gestion/inscriptions-session?id=${row.id}`);
  };

  const getParticipantInscriptionStatus = (status: string) => {
    switch (status) {
      case 'En attente':
        return 'En attente';
      case 'Confirmé':
        return 'Confirmée';
      case 'Annulé':
        return 'Annulée';
      case 'Transférée':
        return 'Annulée';
      default:
        return '-';
    }
  };

  const getParticipantPositionnementStatus = (status: string) => {
    switch (status) {
      case 'Réussi':
        return 'Réalisé';
      case 'Echoué':
        return 'Réalisé';
      case 'Non réalisé':
        return 'A réaliser';
      default:
        return '-';
    }
  };

  const columnsParticipant = [
    {
      content_index: 'nomPrenom',
      label: 'Nom',
      width: 308
    },
    {
      content_index: 'inscription',
      label: 'Inscription',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: getParticipantInscriptionStatus(row.inscription.label)
        })
    },
    {
      content_index: 'positionnement',
      label: 'T. Positionnement',
      width: 140,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.positionnement
            ? getParticipantPositionnementStatus(row.positionnement.label)
            : '-'
        })
    },
    {
      content_index: 'validation',
      label: 'T. valid. acquis',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.validation ? row.validation.label : '-'
        })
    },
    {
      content_index: 'etablissement',
      label: 'Etablissement',
      width: 120
    },
    {
      content_index: 'satisfaction',
      label: 'Satisfaction',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.satisfaction ? row.satisfaction.label : '-'
        }),
      content_icon: (row: Participant) =>
        row.satisfaction && row.satisfaction.label === 'Réalisé'
          ? IconModify
          : undefined,
      on_click: (rowIndex: number) => {},
      on_click_disabled: (row: Participant) =>
        row.satisfaction && row.satisfaction.label !== 'Réalisé',
      class: (row: Participant) => '!flex-grow-0'
    }
  ];

  const columnsParticipants = [
    {
      content_index: 'nomPrenom',
      label: 'Nom',
      width: 308
    },
    {
      content_index: 'statut',
      label: 'Inscription',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: getParticipantInscriptionStatus(row.statut)
        })
    },
    {
      content_index: 'commandeReference',
      label: 'Référence',
      width: 308
    }
  ];

  const columnsFactures = [
    {
      content_index: 'reference',
      label: 'Référence',
      width: 308
    },
    {
      content_index: 'type',
      label: 'Type',
      width: 180
    },
    {
      content_index: 'date',
      label: 'Date',
      width: 120
    },
    {
      content_index: 'montant',
      label: 'Montant',
      width: 120,
      content_custom: (row: any) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montant)
        })
    },
    {
      content_index: 'montantRegle',
      label: 'Payé',
      width: 120,
      content_custom: (row: any) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantRegle)
        })
    },
    {
      content_index: 'factureNom',
      label: 'Facture',
      width: 140,
      on_click: (rowIndex: number) => {},
      content_icon: () => IconDownload,
      class: (row: Facture) => '!flex-grow-0'
    }
  ];
</script>

<style lang="scss" scoped>
  .table-no-space-between {
    :deep(.table-row-content) {
      justify-content: flex-start !important;

      .refonte-table-item {
        flex-grow: 0 !important;
      }
    }
  }
</style>
