<template>
  <template v-if="!loading">
    <refonte-card>
      <sessions-filters />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <refonte-table-number
          v-bind:number="sessions.length"
          v-bind:label="sessions.length > 1 ? 'Sessions' : 'Session'" />
        <IconSeparatorVertical />
        <refonte-table-number
          v-bind:number="nombreConventionsASigner"
          v-bind:label="`${
            nombreConventionsASigner > 1 ? 'Conventions' : 'Convention'
          } à signer`" />
      </div>
      <refonte-separator class="px-[12px]" />
      <sessions-content v-if="!dataLoading" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import SessionsContent from '@/views/_espace/_refonte/gestion/_sessions/sessions-content.vue';
  import {
    SESSIONS_FILTERS_CONFIGURATION,
    useSessionFilterStore,
    useSessionStore
  } from '@/stores/sessions';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import RefonteTableNumber from '@/components/refonte/numbers/refonte-table-number.vue';
  import SessionsFilters from '@/views/_espace/_refonte/gestion/_sessions/sessions-filters.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import { Session } from '@/types/gestion/sessions-types';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);
  const sessionFilterStore = useSessionFilterStore();

  const { filters } = storeToRefs(sessionFilterStore);

  const dataLoading = ref<boolean>(false);
  const sessionStore = useSessionStore();
  const { sessions } = storeToRefs(sessionStore);

  const nombreConventionsASigner = computed(
    () =>
      sessions.value.filter(
        (s: Session) => s.conventionStatut && s.conventionStatut === 'A signer'
      ).length
  );

  const fetchData = async () => {
    dataLoading.value = true;
    await sessionStore.fetchSessions(route.query);
    dataLoading.value = false;
  };

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );

  const initFilters = () => {
    sessionFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(() => {
    initFilters();
    fetchData();
  });

  const router = useRouter();
  watch(filters, async () => {
    let query = api_utils.methods.formatParams(filters.value, [
      SESSIONS_FILTERS_CONFIGURATION.date.name,
      SESSIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      SESSIONS_FILTERS_CONFIGURATION.datePeriodeFin.name
    ]);

    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await router.push({ query });
  });
</script>

<style lang="scss" scoped></style>
