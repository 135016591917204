<template>
  <div class="p-[8px]">
    <template v-if="contrats.length">
      <refonte-table
        key="contrats-1"
        table_index="contrats-1"
        v-bind:data_etablissement="
          activeEntrepriseIds.length !== 1 ? 'clientDenomination' : undefined
        "
        v-bind:data_etablissement_siret="
          activeEntrepriseIds.length !== 1 ? 'clientSiret' : undefined
        "
        v-bind:columns="columns"
        v-bind:data="contrats" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { Contrat } from '@/types/gestion/contrats-types';
  import { useContratStore } from '@/stores/contrats';
  import { storeToRefs } from 'pinia';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconUpload from '@/assets/img/refonte/icons/display/icon-upload.svg?component';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { useEntrepriseStore } from '@/stores/entreprise';

  const columns = [
    {
      content_index: 'periode',
      label: 'Période',
      min_width: 140,
      width: 200
    },
    {
      content_index: 'natureLabel',
      label: 'Nature',
      width: 80
    },
    {
      content_index: 'formateurNomPrenom',
      label: 'Formateur',
      min_width: 110,
      width: 150
    },
    {
      content_index: 'typeLabel',
      label: 'Type',
      width: 70
    },
    {
      content_index: 'formation',
      label: 'Formation',
      content_sub_text: (row: Contrat) => {
        return `${row.modeLabel === 'Intra' ? `${row.modeLabel} - ` : ''}${row.lieu}`;
      },
      min_width: 150,
      width: 200
    },
    {
      content_index: 'organisme',
      label: 'Organisme de formation',
      min_width: 120,
      width: 170
    },
    {
      content_index: 'date',
      label: 'Date session',
      width: 100
    },
    {
      content_index: 'statutLabel',
      label: 'Contrat',
      width: 100,
      content_icon: (row: Contrat) => {
        if (row.statutLabel === 'A signer') {
          return IconUpload;
        }
        if (row.statutLabel === 'Validé') {
          return IconDownload;
        } else {
          return undefined;
        }
      },
      on_click: (rowIndex: number) => {},
      on_click_disabled: (row: Contrat) => row.statutLabel === 'Signé',
      class: (row: Contrat) => '!flex-grow-0'
    }
  ];

  const contratStore = useContratStore();

  const { contrats } = storeToRefs(contratStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseIds } = storeToRefs(entrepriseStore);
</script>

<style lang="scss" scoped></style>
