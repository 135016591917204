<template>
  <template v-if="!loading">
    <refonte-card>
      <satisfaction-filters />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <refonte-table-number
          v-bind:number="satisfaction.length"
          v-bind:label="
            satisfaction.length > 1 ? 'Questionnaires' : 'Questionnaire'
          " />
        <IconSeparatorVertical />
        <refonte-table-number
          v-bind:number="questionnairesARemplir"
          v-bind:label="
            questionnairesARemplir > 1
              ? 'Questionnaires à remplir'
              : 'Questionnaire à remplir'
          " />
      </div>
      <refonte-separator class="px-[12px]" />
      <satisfaction-content v-if="!dataLoading" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import SatisfactionContent from '@/views/_espace/_refonte/gestion/_satisfaction/satisfaction-content.vue';
  import {
    useSatisfactionFilterStore,
    useSatisfactionStore
  } from '@/stores/satisfaction';
  import { storeToRefs } from 'pinia';
  import RefonteTableNumber from '@/components/refonte/numbers/refonte-table-number.vue';
  import SatisfactionFilters from '@/views/_espace/_refonte/gestion/_satisfaction/satisfaction-filters.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import { Satisfaction } from '@/types/gestion/satisfaction-types';
  import { useEntrepriseStore } from '@/stores/entreprise';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeAdminClientIds, activeEntrepriseIds } =
    storeToRefs(entrepriseStore);
  const satisfactionFilterStore = useSatisfactionFilterStore();

  const { filters } = storeToRefs(satisfactionFilterStore);

  const dataLoading = ref<boolean>(false);
  const satisfactionStore = useSatisfactionStore();
  const { satisfaction } = storeToRefs(satisfactionStore);

  const questionnairesARemplir = computed(
    () => satisfaction.value.filter((s: Satisfaction) => !s.repondu).length
  );

  const fetchData = async () => {
    dataLoading.value = true;
    await satisfactionStore.fetchSatisfaction(route.query);
    dataLoading.value = false;
  };

  watch(activeEntrepriseIds, () => {
    fetchData();
  });

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );

  const initFilters = () => {
    satisfactionFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(() => {
    initFilters();
    fetchData();
  });

  const router = useRouter();
  watch(filters, async () => {
    let query = api_utils.methods.formatParams(filters.value, []);
    if (activeAdminClientIds.value && activeAdminClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeAdminClientIds.value
      };
    }
    await router.push({ query });
  });
</script>

<style lang="scss" scoped></style>
