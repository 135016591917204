<template>
  <template v-if="!loading && detail">
    <inscriptions-details-main v-bind:detail="detail" />
    <refonte-collapse
      v-if="detail.titre"
      class="mt-[12px]"
      v-bind:title_icon="IconMyAccount"
      title="Participant"
      v-bind:start_open="true">
      <template v-if="detail.participant">
        <refonte-table
          key="participant-1"
          table_index="participant-1"
          v-bind:columns="columnsParticipant"
          v-bind:data="[detail.participant]">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre"
      class="mt-[12px]"
      v-bind:title_icon="IconTestValidation"
      title="Documents"
      v-bind:start_open="true">
      <div class="flex flex-wrap gap-[8px] pb-[12px]">
        <refonte-item-file
          title="Convention collective"
          v-bind:subtitle="`Commande ${detail.commandeReference}`"
          v-bind:on_click_download="() => console.log('Téléchargement')"
          v-bind:file_signed="false" />
        <refonte-item-file
          title="Convention individuelle"
          subtitle="John Doe"
          v-bind:on_click_download="() => console.log('Téléchargement')"
          v-bind:file_signed="true" />
        <refonte-item-file
          title="PIF"
          v-bind:on_click_download="() => console.log('Téléchargement')"
          signed_label="Signé"
          v-bind:file_signed="true" />
        <refonte-item-file
          title="Certificat"
          no_download_text="A venir"
          v-bind:no_signature_needed="true" />
        <refonte-item-file
          title="Bon de commande"
          v-bind:no_signature_needed="true" />
      </div>
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.factures && detail.factures.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconFactures"
      title="Factures"
      v-bind:start_open="true">
      <template v-if="detail.factures && detail.factures.length > 0">
        <refonte-table
          key="factures-1"
          table_index="factures-1"
          v-bind:columns="columnsFactures"
          v-bind:data="detail.factures">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.programmes && detail.programmes.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconDayAgenda"
      title="Détails">
      <template v-if="detail.programmes.length">
        <div
          v-for="(programme, index) in detail.programmes"
          v-bind:key="`programme-${index}`">
          <refonte-separator
            v-if="index > 0 && detail.programmes.length > 1"
            class="px-[12px]" />
          <refonte-label
            v-if="programme.titre && detail.programmes.length > 1"
            v-bind:label="programme.titre"
            class="px-[16px]" />
          <refonte-table
            key="prestations-1"
            table_index="prestations-1"
            v-bind:columns="columnsPrestations"
            v-bind:data="programme.prestations"
            class="table-no-space-between">
          </refonte-table>
        </div>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
    <refonte-collapse
      v-if="detail.titre && detail.otherParticipants.length > 0"
      class="mt-[12px]"
      v-bind:title_icon="IconConventionCollective"
      v-bind:title="`Autres participants (${detail.otherParticipants.length})`">
      <template v-if="detail.otherParticipants">
        <refonte-table
          v-bind:on_details="goToDetailsParticipant"
          key="otherParticipants-1"
          table_index="otherParticipants-1"
          v-bind:columns="columnsParticipants"
          v-bind:data="detail.otherParticipants"
          class="table-no-space-between">
        </refonte-table>
      </template>
      <refonte-search-results-empty v-else />
    </refonte-collapse>
  </template>
  <spinner
    v-else
    sizes="w-20 h-20"
    thickness="border-8" />
</template>
<script setup lang="ts">
  import Spinner from '@/components/utils/spinner.vue';
  import { h, onMounted, ref } from 'vue';
  import InscriptionsDetailsMain from '@/views/_espace/_refonte/gestion/_inscriptions-details/inscriptions-details-main.vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import IconTestValidation from '@/assets/img/refonte/icons/information/icon-test-validation.svg?component';
  import IconMyAccount from '@/assets/img/refonte/icons/users/icon-my-account.svg?component';
  import IconConventionCollective from '@/assets/img/refonte/icons/users/icon-convention-collective.svg?component';
  import IconFactures from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import {
    Detail,
    Participant,
    Prestation
  } from '@/types/gestion/inscriptions-details-types';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import RefonteItemFile from '@/components/refonte/items/refonte-item-file.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import RefonteLabel from '@/components/refonte/inputs/refonte-label.vue';
  import IconDayAgenda from '@/assets/img/refonte/icons/information/icon-day-agenda.svg?component';
  import dates_helpers from '@/mixin/dates_helpers';
  import number_utils from '@/mixin/number_utils';
  import IconModify from '@/assets/img/refonte/icons/action/icon-modify.svg?component';
  import { Facture } from '@/types/gestion/factures-types';

  const detail = ref<Detail>();
  //const route = useRoute();
  const loading = ref<boolean>(false);
  //const reference = computed(() => route.query.reference);
  //const commandeId = computed(() => route.query.commandeId);
  //const clientId = computed(() => route.query.clientId);

  const fetchFormation = async () => {
    //const response = await API.get(
    //  `client/v2/${clientId.value}/commande/${commandeId.value}/formation/${reference.value}/details`
    //);

    //if (response && response.data) {
    //  detail.value = response.data;
    //}

    detail.value = {
      id: '1',
      catalogueUrl: '#',
      catalogueReference: 'ABC',
      cataloguePubliee: true,
      titre:
        'CAP 3 / Les critères ESG dans tous leurs états - Focus sur le E pour Environnement',
      commandeReference: '123456',
      reference: '120928',
      dureeMinutes: 1620,
      dureeJours: 1.125,
      certifiant: true,
      organisateur: {
        email: 'test@test.test',
        telephone: '0123456789',
        nom: 'CNCC Formation'
      },
      programmes: [
        {
          prestations: [
            {
              id: 1,
              dureeMinutes: 540,
              titre:
                'CAP 3.1 Enjeux et chiffres clés en matière environnementale'
            },
            {
              id: 2,
              dureeMinutes: 540,
              titre: 'CAP 3.2 Appréhension du changement climatique'
            },
            {
              id: 3,
              dureeMinutes: 540,
              titre: 'CAP 3.2 Appréhension du changement climatique'
            }
          ]
        }
      ],
      mode: {
        id: 1,
        label: 'Inter'
      },
      sessionDates: [
        {
          date: '09/01/2024',
          id: 1,
          matin: true,
          aprem: true
        },
        {
          date: '12/01/2024',
          id: 2,
          matin: true,
          aprem: true
        }
      ],
      ficheType: {
        id: 2,
        label: 'e-learning'
      },
      type: {
        id: 2,
        label: 'e-learning'
      },
      mentionsAccessibilite: 'Accessibilité',
      participant: {
        id: 1,
        nom: 'John Doe',
        inscription: {
          id: 2,
          label: 'Confirmé'
        },
        positionnement: {
          id: 1,
          label: 'Réussi'
        },
        validation: {
          id: 2,
          label: 'A venir'
        },
        satisfaction: {
          id: 1,
          label: 'Réalisé'
        },
        etablissement: 'Substanciel'
      },
      otherParticipants: [
        {
          id: 1,
          nom: 'John Doe',
          inscription: {
            id: 1,
            label: 'En attente'
          }
        },
        {
          id: 2,
          nom: 'John Doe',
          inscription: {
            id: 2,
            label: 'Confirmé'
          }
        },
        {
          id: 3,
          nom: 'John Doe',
          inscription: {
            id: 3,
            label: 'Annulé'
          }
        },
        {
          id: 4,
          nom: 'John Doe',
          inscription: {
            id: 4,
            label: 'Transférée'
          }
        }
      ],
      factures: [
        {
          id: '1',
          reference: 'FA24004855',
          type: "Facture d'acompte",
          date: '09/13/2024',
          montant: 50,
          montantRegle: 50,
          factureNom: 'Télécharger'
        }
      ]
    };
  };

  onMounted(async () => {
    loading.value = true;
    await fetchFormation();
    loading.value = false;
  });

  const goToDetailsParticipant = (row: Participant) => {};

  const getParticipantInscriptionStatus = (status: string) => {
    switch (status) {
      case 'En attente':
        return 'En attente';
      case 'Confirmé':
        return 'Confirmée';
      case 'Annulé':
        return 'Annulée';
      case 'Transférée':
        return 'Annulée';
      default:
        return '-';
    }
  };

  const getParticipantPositionnementStatus = (status: string) => {
    switch (status) {
      case 'Réussi':
        return 'Réalisé';
      case 'Echoué':
        return 'Réalisé';
      case 'Non réalisé':
        return 'A réaliser';
      default:
        return '-';
    }
  };

  const columnsParticipant = [
    {
      content_index: 'nom',
      label: 'Nom',
      width: 308
    },
    {
      content_index: 'inscription',
      label: 'Inscription',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: getParticipantInscriptionStatus(row.inscription.label)
        })
    },
    {
      content_index: 'positionnement',
      label: 'T. Positionnement',
      width: 140,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.positionnement
            ? getParticipantPositionnementStatus(row.positionnement.label)
            : '-'
        })
    },
    {
      content_index: 'validation',
      label: 'T. valid. acquis',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.validation ? row.validation.label : '-'
        })
    },
    {
      content_index: 'etablissement',
      label: 'Etablissement',
      width: 120
    },
    {
      content_index: 'satisfaction',
      label: 'Satisfaction',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: row.satisfaction ? row.satisfaction.label : '-'
        }),
      content_icon: (row: Participant) =>
        row.satisfaction && row.satisfaction.label === 'Réalisé'
          ? IconModify
          : undefined,
      on_click: (rowIndex: number) => {},
      on_click_disabled: (row: Participant) =>
        row.satisfaction && row.satisfaction.label !== 'Réalisé',
      class: (row: Participant) => '!flex-grow-0'
    }
  ];

  const columnsParticipants = [
    {
      content_index: 'nom',
      label: 'Nom',
      width: 308
    },
    {
      content_index: 'inscription',
      label: 'Inscription',
      width: 120,
      content_custom: (row: Participant) =>
        h('span', {
          innerHTML: getParticipantInscriptionStatus(row.inscription.label)
        })
    }
  ];

  const columnsPrestations = [
    {
      content_index: 'titre',
      label: ' ',
      width: 540
    },
    {
      content_index: 'dureeMinutes',
      label: 'Durée',
      width: 90,
      content_custom: (row: Prestation) =>
        h('span', {
          innerHTML: dates_helpers.methods.formatMinutesToHours(
            row.dureeMinutes
          )
        })
    }
  ];

  const columnsFactures = [
    {
      content_index: 'reference',
      label: 'Référence',
      width: 308
    },
    {
      content_index: 'type',
      label: 'Type',
      width: 180
    },
    {
      content_index: 'date',
      label: 'Date',
      width: 120
    },
    {
      content_index: 'montant',
      label: 'Montant',
      width: 120,
      content_custom: (row: any) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montant)
        })
    },
    {
      content_index: 'montantRegle',
      label: 'Payé',
      width: 120,
      content_custom: (row: any) =>
        h('span', {
          innerHTML: number_utils.methods.formatCurrency(row.montantRegle)
        })
    },
    {
      content_index: 'factureNom',
      label: 'Facture',
      width: 140,
      on_click: (rowIndex: number) => {},
      content_icon: () => IconDownload,
      class: (row: Facture) => '!flex-grow-0'
    }
  ];
</script>

<style lang="scss" scoped>
  .table-no-space-between {
    :deep(.table-row-content) {
      justify-content: flex-start !important;

      .refonte-table-item {
        flex-grow: 0 !important;
      }
    }
  }
</style>
