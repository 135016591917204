import { API } from '@/http-common.js';
import { Filters } from '@/types/gestion/inscriptions-filters-types';
import {
    Inscription,
    InscriptionResponse,
    typeMapping
} from '@/types/gestion/inscriptions-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocationQuery } from 'vue-router';

export const INSCRIPTIONS_STATUTS_IDS = [1, 2, 3];
export const INSCRIPTIONS_ANNULEES_STATUTS_IDS = [4, 104];

const noFilterState: Filters = {
    clientIds: [],
    statutIds: INSCRIPTIONS_STATUTS_IDS,
    formationId: undefined,
    date: undefined,
    datePeriodeId: undefined,
    datePeriodeDebut: undefined,
    datePeriodeFin: undefined,
    contactId: undefined,
    action: undefined,
    organisateurId: undefined
};

const filterInitialState: Filters = {
    clientIds: [],
    statutIds: INSCRIPTIONS_STATUTS_IDS,
    formationId: undefined,
    date: undefined,
    datePeriodeId: undefined,
    datePeriodeDebut: undefined,
    datePeriodeFin: undefined,
    contactId: undefined,
    action: undefined,
    organisateurId: undefined
};

export const INSCRIPTIONS_FILTERS_CONFIGURATION = {
    clientIds: {
        name: 'clientIds',
        isArray: true
    },
    statutIds: {
        name: 'statutIds',
        isArray: true
    },
    formationId: {
        name: 'formationId',
        isArray: false
    },
    date: {
        name: 'date',
        isArray: false
    },
    datePeriodeId: {
        name: 'datePeriodeId',
        isArray: false
    },
    datePeriodeDebut: {
        name: 'datePeriodeDebut',
        isArray: false
    },
    datePeriodeFin: {
        name: 'datePeriodeFin',
        isArray: false
    },
    contactId: {
        name: 'contactId',
        isArray: false
    },
    action: {
        name: 'action',
        isArray: false
    },
    organisateurId: {
        name: 'organisateurId',
        isArray: false
    }
};

export const useInscriptionFilterStore = defineStore(
    'inscriptions-filter',
    () => {
        const filters = ref<Filters>(filterInitialState);

        const setFilter = (name: string, value: any) => {
            filters.value = { ...filters.value, [name]: value };
        };
        const resetFilters = () => {
            filters.value = noFilterState;
        };

        const parsePayload = (payload: LocationQuery): Filters => {
            const newPayload = { ...payload };

            Object.keys(newPayload).forEach((key) => {
                const filter = INSCRIPTIONS_FILTERS_CONFIGURATION[key] as any;

                if (filter && filter.isArray) {
                    newPayload[key] = newPayload[key].split(',');
                }
                if (
                    [
                        INSCRIPTIONS_FILTERS_CONFIGURATION.date.name,
                        INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeDebut
                            .name,
                        INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeFin.name
                    ].includes(key)
                ) {
                    newPayload[key] = new Date(newPayload[key]);
                }
            });

            return newPayload;
        };

        const initFiltersFromQuery = (payload: LocationQuery) => {
            const parsedPayload = parsePayload(payload) as Filters;
            if (payload && Object.keys(payload).length > 0) {
                filters.value = {
                    ...noFilterState,
                    ...parsedPayload
                };
            } else {
                filters.value = {
                    ...filterInitialState,
                    ...parsedPayload
                };
            }
        };

        return {
            resetFilters,
            setFilter,
            filters,
            initFiltersFromQuery
        };
    }
);

export const useInscriptionStore = defineStore('inscriptions', () => {
    const inscriptions = ref<Inscription[]>([]);

    const fetchInscriptions = async (query: Filters) => {
        const response = await API.get(`client/v2/inscriptions`, {
            params: query
        });
        if (response?.data) {
            inscriptions.value = response?.data.map((i: InscriptionResponse) =>
                mapInscription(i)
            );
        }
    };

    const mapInscription = (i: InscriptionResponse): Inscription => {
        return {
            ...i,
            clientDenomination: i?.client?.denomination,
            clientSiret: i.client.siret ?? undefined,
            participantNomPrenom: i.nomPrenom,
            organisateur: i.organisateur,
            typeShortcut: typeMapping[i.produit.type.id],
            typeLabel: i.produit.type.label,
            statutLabel: i.statut.label,
            ...(i.convention?.id && {
                conventionStatut: buildDocumentStatut(
                    i.convention?.disponible,
                    i.convention?.signee,
                    i.convention?.validee
                )
            }),
            ...(i.pif?.id && {
                pifStatut: buildDocumentStatut(
                    i.pif?.disponible,
                    i.pif?.signee,
                    i.pif?.validee
                )
            }),
            ...(i.hasCertificats && {
                certificatLabel: 'Télécharger'
            }),
            ...(i.testPositionnement && {
                testPositionnementStatut: buildTestPositionnementStatut(
                    i.testPositionnement?.disponible,
                    i.testPositionnement?.realise
                )
            }),
            clientId: i.client.id,
            titre: `${i.produit.titre}${i.familleStageEC ? ' (Stage EC)' : ''}${i.familleStageCAC ? ' (Stage CAC)' : ''}`,
            datesFormations: buildDate(i.dateDebut, i.dateFin)
        };
    };

    const buildTestPositionnementStatut = (
        disponible?: boolean,
        realise?: boolean
    ) => {
        if (!disponible) {
            return 'Non requis';
        } else if (disponible && !realise) {
            return 'Non réalisé';
        } else if (realise) {
            return 'Réalisé';
        }
    };
    const buildDocumentStatut = (
        disponible?: boolean,
        signee?: boolean,
        validee?: boolean
    ) => {
        if (disponible && !signee && !validee) {
            return 'A signer';
        } else if (signee) {
            return 'Signée';
        } else if (validee) {
            return 'Validée';
        }
    };
    const buildDate = (dateDebut: string, dateFin: string): string => {
        if (dateDebut === dateFin) {
            return dateDebut;
        } else {
            return `${dateDebut} - ${dateFin}`;
        }
    };
    return {
        inscriptions,
        fetchInscriptions
    };
});
