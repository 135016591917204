<template>
  <template v-if="!loading">
    <refonte-page-title v-bind:description_in_card="true">
      Bienvenue dans le nouvel Espace Gestion Jinius<br />
      Ce nouvel espace vous permettra de trouver plus facilement les
      informations sur vos formations ainsi que les documents associés.<br />
      Cet écran vous fournit également la liste des actions que vous avez à
      faire.
    </refonte-page-title>
    <refonte-collapse
      class="mt-[12px]"
      v-bind:title_icon="IconFormations"
      title="Inscriptions"
      v-bind:start_open="true">
      <div class="btn-dashbord-container">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="() => router.push('/refonte/gestion/inscriptions')">
          <b>{{ indicateurs.nombreInscriptionsEnAttente }}</b> inscription(s)
          actuellement en attente de confirmation de l'organisme de formation
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () =>
              router.push(
                '/refonte/gestion/inscriptions?action=T_POS_NON_REALISE'
              )
          ">
          <b>{{
            indicateurs.nombreInscriptionsTestsPositionnementNonRealise
          }}</b>
          participant(s) n'ont pas encore rempli leur test de positionnement.
          Ceci est nécessaire pour confirmer leur inscription
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () =>
              router.push(
                '/refonte/gestion/inscriptions?action=CONVENTIONS_A_SIGNER'
              )
          ">
          <b>{{ indicateurs.nombreInscriptionsConventionsASigner }}</b>
          convention(s) actuellement en attente de signature
        </refonte-button>
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () =>
              router.push('/refonte/gestion/inscriptions?action=PIF_A_SIGNER')
          ">
          <b>{{ indicateurs.nombreInscriptionsPifsASigner }}</b> PIF (Protocole
          Individuel de Formation) actuellement en attente de signature
        </refonte-button>
        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="
              () => router.push('/refonte/gestion/inscriptions')
            ">
            Retrouvez vos inscriptions dans le menu Inscriptions
          </refonte-button>
        </div>
      </div>
    </refonte-collapse>
    <refonte-collapse
      class="mt-[12px]"
      v-bind:title_icon="IconCommandes"
      title="Commandes"
      v-bind:start_open="true">
      <div class="btn-dashbord-container">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () => router.push('/refonte/gestion/commandes?commandeReglee=false')
          ">
          <b>2</b> commande(s) non réglée(s)
        </refonte-button>
        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="() => router.push('/refonte/gestion/commandes')">
            Retrouvez vos commandes dans le menu Commandes
          </refonte-button>
        </div>
      </div>
    </refonte-collapse>
    <refonte-collapse
      class="mt-[12px]"
      v-bind:title_icon="IconFactures"
      title="Factures"
      v-bind:start_open="true">
      <div class="btn-dashbord-container">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () => router.push('/refonte/gestion/factures?typeIds=2')
          ">
          <b>{{ indicateurs.nombreFacturesARegler }}</b> facture(s) non
          acquittée(s)
        </refonte-button>
        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="() => router.push('/refonte/gestion/factures')">
            Retrouvez toutes vos factures dans le menu Factures
          </refonte-button>
        </div>
      </div>
    </refonte-collapse>
    <refonte-collapse
      v-if="hasContrats"
      class="mt-[12px]"
      v-bind:title_icon="IconTestPositionnement"
      title="Contrats Formateurs"
      v-bind:start_open="true">
      <div class="btn-dashbord-container">
        <refonte-button
          variant="secondary"
          class="btn-dashboard"
          v-bind:on_click="
            () => router.push('/refonte/gestion/contrats?statutId=1')
          ">
          <b>{{ indicateurs.nombreContratsASigner }}</b> contrat(s) actuellement
          en attente de signature
        </refonte-button>
        <div>
          <refonte-separator />
          <refonte-button
            variant="text"
            v-bind:on_click="() => router.push('/refonte/gestion/contrats')">
            Retrouvez vos contrats formateurs dans le menu Contrats Formateurs
          </refonte-button>
        </div>
      </div>
    </refonte-collapse>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>
<script setup lang="ts">
  import RefontePageTitle from '@/components/refonte/titles/refonte-page-title.vue';
  import RefonteCollapse from '@/components/refonte/collapse/refonte-collapse.vue';
  import IconCommandes from '@/assets/img/refonte/icons/menu/icon-orders.svg?component';
  import IconFactures from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';
  import IconFormations from '@/assets/img/refonte/icons/menu/icon-training.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { router } from '@/router';
  import { onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import IconTestPositionnement from '@/assets/img/refonte/icons/information/icon-test-positionnement.svg?component';
  import { API } from '@/http-common';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import { Entreprise } from '@/types/gestion/entreprise';

  type Indicateurs = {
    nombreInscriptionsEnAttente: number;
    nombreInscriptionsConventionsASigner: number;
    nombreInscriptionsPifsASigner: number;
    nombreContratsASigner: number;
    nombreInscriptionsTestsPositionnementNonRealise: number;
    nombreFacturesARegler: number;
  };

  const loading = ref(false);

  const indicateurs = ref<Indicateurs>({
    nombreInscriptionsEnAttente: 0,
    nombreInscriptionsConventionsASigner: 0,
    nombreInscriptionsPifsASigner: 0,
    nombreContratsASigner: 0,
    nombreInscriptionsTestsPositionnementNonRealise: 0,
    nombreFacturesARegler: 0
  });
  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds, activeEntrepriseIds, entreprises } =
    storeToRefs(entrepriseStore);
  const fetchIndicateurs = async (query: { clientIds?: number[] }) => {
    const response = await API.get(`client/v2/indicateurs`, {
      params: query
    });
    if (response?.data) {
      indicateurs.value = response?.data;
    }
  };

  const fetchData = async () => {
    loading.value = true;
    let query = {};
    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await fetchIndicateurs(query);
    loading.value = false;
  };

  const hasContrats = ref<boolean>(false);

  const handleHasContrats = () => {
    if (
      (activeEntrepriseIds.value.length === 0 &&
        (entreprises.value as Entreprise[]).some((e) => e.hasContrats)) ||
      (activeEntrepriseIds.value.length > 0 &&
        (entreprises.value as Entreprise[])
          .filter((e) => activeEntrepriseIds.value.includes(e.entrepriseId))
          .some((e) => e.hasContrats))
    ) {
      hasContrats.value = true;
    } else {
      hasContrats.value = false;
    }
  };

  onMounted(() => {
    fetchData();
    handleHasContrats();
  });

  watch(activeClientIds, () => {
    fetchData();
  });

  watch(activeEntrepriseIds, (value) => {
    handleHasContrats();
  });
</script>

<style lang="scss" scoped>
  .btn-dashbord-container {
    @apply flex flex-col;
    margin-bottom: 8px;
    gap: 8px;
    padding: 0 10px;

    .btn-dashboard {
      @apply w-full justify-between text-left text-refonte-black;
      max-width: 100% !important;

      b {
        @apply mr-1 text-refonte_blue-600;
      }
    }
    :deep(.refonte-btn.btn-secondary span) {
      overflow: initial;
      text-overflow: initial;
      display: initial;
      -webkit-box-orient: initial;
      -webkit-line-clamp: initial;
      font-size: 14px;
      font-weight: 400;
      line-height: 125%;
    }
  }
</style>
