import { Document } from '@/types/gestion/inscriptions-types';
import http_functions from './http_functions.js';

export const downloadBonCommande = (
    clientId: number | undefined,
    commandeId: number | undefined
) => {
    http_functions.methods.downloadBlobFile(
        `client/${clientId}/commande/${commandeId}/bon/file`,
        'bon_commande.pdf'
    );
};
export const downloadCertificat = (
    clientId: number | undefined,
    certificatId: number | undefined
) => {
    http_functions.methods.downloadFile(
        `client/${clientId}/certificat/${certificatId}/file`,
        'certificat.pdf'
    );
};

export const downloadPif = (
    clientId: number | undefined,
    pif: Document | undefined
) => {
    if (pif?.signee) {
        http_functions.methods.downloadFile(
            `client/${clientId}/pif/${pif.id}/file/signee`,
            'convention.pdf'
        );
    } else if (pif?.validee) {
        http_functions.methods.downloadFile(
            `client/${clientId}/pif/${pif.id}/file/validee`,
            'convention.pdf'
        );
    } else if (pif?.disponible) {
        http_functions.methods.downloadFile(
            `client/${clientId}/pif/${pif.id}/file`,
            'convention.pdf'
        );
    }
};

export const downloadConvention = (
    clientId: number | undefined,
    convention: Document | undefined
) => {
    if (convention?.signee && clientId) {
        http_functions.methods.downloadFile(
            `client/${clientId}/convention/${convention.id}/file/signee`,
            'convention.pdf'
        );
    } else if (convention?.validee) {
        http_functions.methods.downloadFile(
            `client/${clientId}/convention/${convention.id}/file/validee`,
            'convention.pdf'
        );
    } else if (convention?.disponible) {
        http_functions.methods.downloadFile(
            `client/${clientId}/convention/${convention.id}/file`,
            'convention.pdf'
        );
    }
};
